import React, { useState, useEffect } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Box, Button, Container, Input, Text, Alert, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { userPool } from '../contexts/AuthContext';
import axios from 'axios';
import config from '../config';

const ConfirmSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [userSub, setUserSub] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
      setUserSub(location.state.userSub);
      setFirstName(location.state.firstName);
      setLastName(location.state.lastName);
      setDisplayName(location.state.displayName);
    } else {
      setError('Email not provided. Please go back to the sign-up page.');
    }
  }, [location.state]);

  const createUserProfile = async () => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/api/create_user_profile`, {
        id: userSub,
        email: email,
        display_name: displayName,
        first_name: firstName,
        last_name: lastName
      });
      console.log('User profile created:', response.data);
      return true;
    } catch (error) {
      console.error('Error creating user profile:', error);
      toast({
        title: "Profile creation failed",
        description: "We couldn't create your profile. Please try again or contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!email) {
      setError('Email is required. Please go back to the sign-up page.');
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.confirmRegistration(code, true, async (err, result) => {
      if (err) {
        setError(err.message || 'Error during confirmation');
      } else {
        setSuccess('Email confirmed successfully!');
        const profileCreated = await createUserProfile();
        if (profileCreated) {
          toast({
            title: "Sign-up complete",
            description: "Your account has been confirmed and your profile created.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setTimeout(() => navigate('/dashboard'), 2000);
        } else {
          navigate('/login');
        }
      }
    });
  };

  const handleResendCode = () => {
    setError('');
    setSuccess('');

    if (!email) {
      setError('Email is required. Please go back to the sign-up page.');
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        setError(err.message || 'Error during resending code');
      } else {
        setSuccess('Verification code resent successfully!');
      }
    });
  };

  return (
    <Container maxW="sm">
      <Box mt={8}>
        <Text fontSize="2xl" mb={4}>Confirm Sign Up</Text>
        {error && <Alert status="error" mb={4}>{error}</Alert>}
        {success && <Alert status="success" mb={4}>{success}</Alert>}
        <form onSubmit={handleConfirmSignUp}>
          <FormControl mb={4}>
            <FormLabel>Verification Code</FormLabel>
            <Input type="text" value={code} onChange={(e) => setCode(e.target.value)} required />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full">Confirm</Button>
        </form>
        <Button onClick={handleResendCode} variant="link" width="full" mt={4} colorScheme="blue">
          Resend Code
        </Button>
      </Box>
    </Container>
  );
};

export default ConfirmSignUp;