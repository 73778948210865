import React from 'react';
import { Box, Flex, Text, Button, Menu, MenuButton, MenuList, MenuItem, Avatar } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const NavBar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const displayName = user?.display_name|| 'User';

  return (
    <Box bg="blue.500" px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="white"
          cursor="pointer"
          onClick={handleNavigateToDashboard}
        >
          OsceCoach
        </Text>
        <Flex alignItems={'center'}>
          <Text color="white" mr={4}>Welcome, {displayName}</Text>
          <Menu>
            <MenuButton
              as={Button}
              rounded={'full'}
              variant={'link'}
              cursor={'pointer'}
              minW={0}
            >
              <Avatar size={'sm'} name={displayName} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;