import React, { useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Box, Button, Container, Input, Text, Alert, AlertIcon, FormControl, FormLabel, Link } from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';  // Import Link for navigation
import { useAuth } from '../contexts/AuthContext';
import { userPool } from '../contexts/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: () => {
        setIsAuthenticated(true);
        navigate('/dashboard');
      },
      onFailure: (err) => {
        setError(err.message || 'Login failed');
        setIsAuthenticated(false);
      },
    });
    setIsLoading(false);
  };

  return (
    <Container maxW="sm">
      <Box mt={8}>
        <Text fontSize="2xl" mb={4}>Login</Text>
        {error && <Alert status="error"><AlertIcon />{error}</Alert>}
        <form onSubmit={handleLogin}>
          <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Password</FormLabel>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full" isLoading={isLoading}>
            Login
          </Button>
        </form>
<Text mt={4} textAlign="center">
          New to OsceCoach?{' '}
          <Link as={RouterLink} to="/signup" color="blue.500">
            Sign up
          </Link>
        </Text>
      </Box>
    </Container>
  );
};

export default Login;
