import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import { FaPaperPlane, FaMicrophone, FaStop } from 'react-icons/fa';
import { useSimulationMode } from '../contexts/SimulationModeContext';
import config from '../config';
import {
  Text,
  Box,
  Input,
  Button,
  Container,
  IconButton,
  VStack,
  HStack,
  Flex,
  useToast,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Switch,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  UnorderedList,
  ListItem,
  Progress,
} from '@chakra-ui/react';

const { WEBSOCKET_URL, API_BASE_URL } = config;


const WelcomeOverlay = ({ isOpen, initialMessage, onStart, currentMode, showWelcomeTimer, welcomeTimeRemaining, hasStartedSimulation }) => (
  <Modal 
    isOpen={isOpen && !hasStartedSimulation} 
    onClose={() => {}} 
    isCentered 
    size="xl" 
    closeOnOverlayClick={false} 
    closeOnEsc={false}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        Welcome to the Simulation
        {currentMode.id === 'exam' && showWelcomeTimer && (
          <Box mt={4}>
            <Text fontSize="lg" color="blue.500">
              Time until auto-start: {Math.floor(welcomeTimeRemaining / 60)}:
              {(welcomeTimeRemaining % 60).toString().padStart(2, '0')}
            </Text>
            <Progress 
              value={(welcomeTimeRemaining / 120) * 100} 
              colorScheme="blue" 
              size="sm" 
              borderRadius="full"
              mt={2}
            />
          </Box>
        )}
      </ModalHeader>
      <ModalBody>
        <VStack spacing={4}>
          <Text>{initialMessage}</Text>
          {currentMode.id === 'exam' && (
            <Box bg="yellow.100" p={4} borderRadius="md" width="100%">
              <Text fontWeight="bold">Exam Mode Rules:</Text>
              <UnorderedList mt={2}>
                <ListItem>You have 7 minutes to complete this simulation</ListItem>
                <ListItem>No hints or feedback will be provided during the exam</ListItem>
                <ListItem>The simulation will automatically end when time expires</ListItem>
                <ListItem>The exam will auto-start in 2 minutes if you don't start it manually</ListItem>
              </UnorderedList>
            </Box>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
        {currentMode.id === 'exam' ? (
          <Button 
            colorScheme="blue" 
            onClick={onStart}
          >
            Start Now
          </Button>
        ) : (
          <Button 
            colorScheme="blue" 
            onClick={onStart}
          >
            Start Simulation
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

/**
 * SimulationInterface Component
 * 
 * Main component for handling medical simulation interactions including:
 * - Real-time chat with AI agents
 * - Audio recording and playback
 * - Session management
 * - Artifact display
 * - Mode-specific behaviors (Practice, Exam, Demo)
 */
const SimulationInterface = () => {
  // =========================================
  // State Declarations
  // =========================================
  
  // Core simulation state
  const [sessionId, setSessionId] = useState(() => uuidv4());
  const [messages, setMessages] = useState([]);
  const [scenarioDetails, setScenarioDetails] = useState(null);
  const [initialMessage, setInitialMessage] = useState('');
  const [feedback, setFeedback] = useState(null);
  
  // UI state
  const [input, setInput] = useState('');
  const [showTextInput, setShowTextInput] = useState(false);
  const [currentArtifact, setCurrentArtifact] = useState(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(true);
  const [showCountdown, setShowCountdown] = useState(false);
  
  // Welcome overlay timer
  const [welcomeTimeRemaining, setWelcomeTimeRemaining] = useState(120);
  const [showWelcomeTimer, setShowWelcomeTimer] = useState(false);
  const [hasStartedSimulation, setHasStartedSimulation] = useState(false);


  // Audio state
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  
  // Loading and simulation status
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isSimulationEnded, setIsSimulationEnded] = useState(false);
  const [isEndingSimulation, setIsEndingSimulation] = useState(false);

  // =========================================
  // Hooks and Refs
  // =========================================
  
  // Simulation mode context
  const { 
    currentMode, 
    timeRemaining, 
    setTimeRemaining,
  } = useSimulationMode();
  
  // Refs
  const socketRef = useRef(null);
  const audioRef = useRef(null);
  const messagesEndRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  
  // Dialog refs
  const cancelRefRestart = useRef();
  const cancelRefGoBack = useRef();
  
  // Custom hooks
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();
  const { scenarioId } = useParams();
  
  // UI hooks
  const {
    isOpen: isSettingsModalOpen,
    onOpen: onOpenSettingsModal,
    onClose: onCloseSettingsModal
  } = useDisclosure();
  
  const {
    isOpen: isRestartConfirmOpen,
    onOpen: onOpenRestartConfirm,
    onClose: onCloseRestartConfirm
  } = useDisclosure();
  
  const {
    isOpen: isGoBackConfirmOpen,
    onOpen: onOpenGoBackConfirm,
    onClose: onCloseGoBackConfirm
  } = useDisclosure();
  
  // Theme values
  const bg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const cardBg = useColorModeValue('white', 'gray.700');
  const primaryColor = useColorModeValue('teal.500', 'teal.300');
  const menuBg = useColorModeValue('teal.500', 'teal.200');
  const menuColor = useColorModeValue('white', 'gray.800');

  // =========================================
  // Setup Effects
  // =========================================
  
  // Initial setup effect
  useEffect(() => {
    if (scenarioId && sessionId) {
      setupSocket(sessionId);
      setupSimulation();
    } else {
      handleSetupError('Missing session or scenario information');
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [scenarioId, sessionId]);

  // Scroll effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Audio handling effect
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handlePlay = () => setIsAudioPlaying(true);
      const handleEnded = () => {
        setIsAudioPlaying(false);
        setIsWaitingForResponse(false);
      };
      const handlePause = () => setIsAudioPlaying(false);

      audio.addEventListener('play', handlePlay);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('pause', handlePause);

      return () => {
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('pause', handlePause);
      };
    }
  }, []);

  // Exam mode welcome overlay timer
  useEffect(() => {
    if (isOverlayOpen && currentMode.id === 'exam' && !hasStartedSimulation) {
      setShowWelcomeTimer(true);
      const timer = setInterval(() => {
        setWelcomeTimeRemaining(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleStartButtonClick();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
    return () => {};
  }, [isOverlayOpen, currentMode.id, hasStartedSimulation]);

  // Exam mode timer countdown
  useEffect(() => {
    let timer;

    if (hasStartedSimulation && currentMode.id === 'exam' && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNormalEnding();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [hasStartedSimulation, currentMode.id, timeRemaining]);

  // Audio handling effect
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handlePlay = () => setIsAudioPlaying(true);
      const handleEnded = () => {
        setIsAudioPlaying(false);
        setIsWaitingForResponse(false);
      };
      const handlePause = () => setIsAudioPlaying(false);

      audio.addEventListener('play', handlePlay);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('pause', handlePause);

      return () => {
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('pause', handlePause);
      };
    }
  }, []);

  // =========================================
  // Socket and Setup Functions
  // =========================================

  const setupSocket = (newSessionId) => {
    socketRef.current = io(WEBSOCKET_URL, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socketRef.current.on('connect', () => console.log('Socket connected'));
    socketRef.current.on('new_message', handleNewMessage);
    socketRef.current.on('simulation_ended', handleNormalEnding);
    socketRef.current.on('new_audio_ready', (data) => playAudio(data.audioPath));
  };

  const setupSimulation = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/setup_simulation`, {
        scenarioId,
        sessionId,
        userId: user?.sub,
        mode: currentMode.id
      });
      setInitialMessage(response.data.initialMessage);
      setScenarioDetails(response.data.scenarioDetails);
      
      if (currentMode.id === 'exam') {
        setTimeRemaining(currentMode.features.examDuration);
      }
    } catch (error) {
      handleError('Failed to set up simulation', error);
      navigate('/scenarios');
    }
  };

  // =========================================
  // Message Handling Functions
  // =========================================

  const handleNewMessage = (message) => {
    if (!message.isUser) {
      const artifactRegex = /ARTIFACT_START([\s\S]*?)ARTIFACT_END/g;
      let artifact = null;
      let cleanedContent = message.content;

      const match = artifactRegex.exec(message.content);
      if (match) {
        artifact = match[1].trim();
        cleanedContent = cleanedContent.replace(match[0], '');
      }

      const newMessage = {
        ...message,
        content: cleanedContent.trim(),
        hasArtifact: !!artifact
      };

      setMessages(prev => [...prev, newMessage]);
      if (artifact && currentMode.features.showHints) {
        setCurrentArtifact(artifact);
      }
      setIsWaitingForResponse(false);
    }
  };

  const handleTextSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() && !isWaitingForResponse && !isSimulationEnded) {
      const userMessage = { 
        sender: 'You', 
        content: input.trim(), 
        isUser: true 
      };
      setMessages(prev => [...prev, userMessage]);
      await sendMessageToServer(input.trim());
      setInput('');
    }
  };

  const sendMessageToServer = async (message, timestamp = null) => {
    setIsWaitingForResponse(true);
    try {
      await axios.post(`${API_BASE_URL}/api/send_message`, {
        sessionId,
        message,
        userId: user?.sub,
        timestamp,
        mode: currentMode.id
      });
    } catch (error) {
      handleError('Failed to send message', error);
      setIsWaitingForResponse(false);
    }
  };

  // =========================================
  // Audio Handling Functions
  // =========================================

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        sendAudioToServer(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      handleError('Failed to start recording', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToServer = async (blob) => {
    setIsWaitingForResponse(true);
    const formData = new FormData();
    formData.append('audio', blob, 'audio.wav');
    formData.append('sessionId', sessionId);
    formData.append('userId', user?.sub);
    formData.append('mode', currentMode.id);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/transcribe_audio`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      
      if (response.data.text?.trim()) {
        const transcribedText = response.data.text.trim();
        const timestamp = response.data.timestamp;
        const userMessage = { sender: 'You', content: transcribedText, isUser: true };
        setMessages(prev => [...prev, userMessage]);
        await sendMessageToServer(transcribedText, timestamp);
      } else {
        showToast('warning', 'No speech detected. Please try again.');
        setIsWaitingForResponse(false);
      }
    } catch (error) {
      handleError('Failed to process audio', error);
      setIsWaitingForResponse(false);
    }
  };

  const playAudio = (audioPath) => {
    if (audioRef.current) {
      const fileName = audioPath.split('/').pop();
      audioRef.current.src = `${API_BASE_URL}/api/audio/${fileName}`;
      audioRef.current.play().catch(e => console.error('Error playing audio:', e));
    }
  };

  // =========================================
  // Simulation Control Functions
  // =========================================

  const handleStartButtonClick = async () => {
    setShowWelcomeTimer(false);
    setWelcomeTimeRemaining(0);
    setIsOverlayOpen(false);
    setHasStartedSimulation(true);
  
    if (currentMode.id === 'exam') {
    setTimeRemaining(7 * 60);
    }
    
    try {
      await axios.post(`${API_BASE_URL}/api/start_simulation`, {
        sessionId,
        userId: user?.sub,
        mode: currentMode.id
      });
    } catch (error) {
      handleError('Failed to start simulation', error);
      navigate('/scenarios');
    }
  };

  const handleNormalEnding = async () => {
    setIsEndingSimulation(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/end_simulation_normal`, {
        sessionId,
        userId: user?.sub,
        scenarioName: scenarioDetails?.name,
        scenarioId,
        mode: currentMode.id
      });
      setFeedback(response.data.feedback);
      setIsSimulationEnded(true);
    } catch (error) {
      handleError('Failed to end simulation', error);
    } finally {
      setIsEndingSimulation(false);
    }
  };

  const handleAnomalyEnding = async () => {
    if (isSimulationEnded) return;
    try {
      await axios.post(`${API_BASE_URL}/api/end_simulation_anomaly`, {
        sessionId,
        userId: user?.sub,
        scenarioName: scenarioDetails?.name,
        scenarioId,
        mode: currentMode.id
      });
    } catch (error) {
      console.error('Error ending simulation (anomaly):', error);
    }
  };

  // =========================================
  // Navigation and UI Control Functions
  // =========================================

  const handleGoToResult = () => {
    if (isSimulationEnded && feedback) {
      navigate(`/simulation-result/${sessionId}`, { 
        state: { 
          feedback,
          mode: currentMode.id
        } 
      });
    }
  };

  const handleRestart = async () => {
    setMessages([]);
    setInput('');
    setIsSimulationEnded(false);
    setFeedback(null);
    setInitialMessage('');
    setSessionId(uuidv4());
    setIsOverlayOpen(true);
    setHasStartedSimulation(false);
    if (currentMode.id === 'exam') {
      setTimeRemaining(null);
      setWelcomeTimeRemaining(120);
      setShowWelcomeTimer(true);
    }
  };

  const handleGoBack = () => {
    navigate('/scenarios');
  };

  const confirmRestart = async () => {
    onCloseRestartConfirm();
    onCloseSettingsModal();
    await handleAnomalyEnding();
    await handleRestart();
  };

  const confirmGoBack = async () => {
    onCloseGoBackConfirm();
    onCloseSettingsModal();
    await handleAnomalyEnding();
    handleGoBack();
  };

  // =========================================
  // Helper Functions
  // =========================================

  const handleError = (message, error) => {
    console.error(`${message}:`, error);
    showToast('error', error.response?.data?.detail || `${message}. Please try again.`);
  };

  const handleSetupError = (message) => {
    console.error(message);
    showToast('error', message);
    navigate('/scenarios');
  };

  const showToast = (status, description) => {
    toast({
      title: status === 'error' ? 'Error' : 'Warning',
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  // =========================================
  // Sub-Components
  // =========================================

  const MessageBox = ({ isUser, children }) => (
    <Box
      bg={isUser ? "blue.100" : "gray.100"}
      color={isUser ? "blue.800" : "gray.800"}
      borderRadius="md"
      p={2}
      mb={2}
      alignSelf={isUser ? "flex-end" : "flex-start"}
      maxWidth="80%"
    >
      {children}
    </Box>
  );

  const CountdownDisplay = React.memo(({ timeRemaining }) => (
    <Box mt={2}>
      <Text fontSize="lg" color="blue.500">
        Starting in: {Math.floor(timeRemaining / 60)}:
        {(timeRemaining % 60).toString().padStart(2, '0')}
      </Text>
      <Text fontSize="sm" color="gray.500">
        You can start immediately or wait for auto-start
      </Text>
    </Box>
  ));
  
  const ExamTimer = () => (
    <Box
      position="fixed"
      top="20px"
      right="20px"
      bg={timeRemaining <= 60 ? "red.100" : "white"}
      p={4}
      borderRadius="md"
      boxShadow="md"
      zIndex={1000}
    >
      <VStack spacing={1}>
        <Text fontWeight="bold">Time Remaining:</Text>
        <Text 
          fontSize="xl" 
          color={timeRemaining <= 60 ? "red.600" : "black"}
        >
          {Math.floor(timeRemaining / 60)}:
          {(timeRemaining % 60).toString().padStart(2, '0')}
        </Text>
      </VStack>
    </Box>
  );

  const ArtifactPanel = ({ artifact }) => (
    <Box
      bg={cardBg}
      p={4}
      borderRadius="md"
      boxShadow="md"
      width="100%"
      height="auto"
      maxHeight="80vh"
      overflowY="auto"
    >
      <Text fontWeight="bold" mb={2}>Artifact:</Text>
      <Box
        p={6}
        borderRadius="sm"
        boxShadow="sm"
        sx={{
          ul: { paddingLeft: '20px' },
          li: { color: 'black' },
          ol: { paddingLeft: '20px' }
        }}
      >
        <ReactMarkdown>{artifact}</ReactMarkdown>
      </Box>
    </Box>
  );

  const SettingsModal = ({ isOpen, onClose, onOpenRestartConfirm, onOpenGoBackConfirm }) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Simulation Menu</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Button 
              colorScheme="red" 
              onClick={onOpenRestartConfirm} 
              width="100%"
            >
              Restart Simulation
            </Button>
            <Button colorScheme="gray" onClick={onOpenGoBackConfirm} width="100%">
              Go Back to Scenarios
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  // =========================================
  // Main Render
  // =========================================
  
  return (
    <Flex direction="row" minHeight="100vh" bg={bg} maxWidth="1500px" margin="0 auto">
      {/* Main Content Area */}
      <Box 
        flex="1"
        minWidth="0"
        backgroundImage="url('data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' fill-rule=\'evenodd\'%3E%3Cg fill=\'%239C92AC\' fill-opacity=\'0.08\'%3E%3Cpath d=\'M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\')"
      >
        <Container maxW="container.md" p={6}>
          {/* Modals */}
          <WelcomeOverlay 
            isOpen={isOverlayOpen} 
            initialMessage={initialMessage}
            onStart={handleStartButtonClick}  
            currentMode={currentMode}
            showWelcomeTimer={showWelcomeTimer}
            welcomeTimeRemaining={welcomeTimeRemaining}
            hasStartedSimulation={hasStartedSimulation}
          />
          
          <SettingsModal 
            isOpen={isSettingsModalOpen}
            onClose={onCloseSettingsModal}
            onOpenRestartConfirm={onOpenRestartConfirm}
            onOpenGoBackConfirm={onOpenGoBackConfirm}
          />

          {/* Confirmation Dialogs */}
          <AlertDialog
            isOpen={isRestartConfirmOpen}
            leastDestructiveRef={cancelRefRestart}
            onClose={onCloseRestartConfirm}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Restart Simulation
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to restart the simulation? This will end the current session.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRefRestart} onClick={onCloseRestartConfirm}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={confirmRestart} ml={3}>
                    Restart
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <AlertDialog
            isOpen={isGoBackConfirmOpen}
            leastDestructiveRef={cancelRefGoBack}
            onClose={onCloseGoBackConfirm}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Go Back to Scenarios
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to go back to scenario selection? This will end the current simulation.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRefGoBack} onClick={onCloseGoBackConfirm}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={confirmGoBack} ml={3}>
                    Go Back
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* Header */}
          <Flex justify="space-between" align="center" mb={6}>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Medical Simulation {currentMode.id === 'exam' ? '- Exam Mode' : ''}
              </Text>
              <Text>Scenario ID: {scenarioId || 'No scenario selected'}</Text>
            </Box>
            <Tooltip label="Settings (Restart, Go Back)" placement="bottom-end">
              <Button
                onClick={onOpenSettingsModal}
                size="md"
                bg={menuBg}
                color={menuColor}
                _hover={{ bg: useColorModeValue('teal.600', 'teal.300') }}
                boxShadow="md"
              >
                Menu
              </Button>
            </Tooltip>
          </Flex>

          {/* Exam Timer */}
          {currentMode.id === 'exam' && timeRemaining > 0 && !isOverlayOpen && (
            <ExamTimer />
          )}

          {/* Initial Message Display */}
          {!isOverlayOpen && initialMessage && (
            <Box bg={cardBg} p={4} borderRadius="md" mb={4} boxShadow="md">
              <Text fontWeight="bold">{initialMessage}</Text>
            </Box>
          )}

          {/* Chat Interface */}
          <Box 
            bg={cardBg} 
            p={4} 
            borderRadius="md" 
            height="600px" 
            overflowY="auto" 
            mb={4}
            boxShadow="md"
            flex="1"
          >
            <VStack spacing={4} align="stretch">
              {messages.map((msg, index) => (
                <Flex key={index} justifyContent={msg.isUser ? 'flex-end' : 'flex-start'}>
                  <MessageBox isUser={msg.isUser}>
                    <Text fontWeight="bold">{msg.sender}:</Text> {msg.content}
                  </MessageBox>
                </Flex>
              ))}
              <Box ref={messagesEndRef} />
            </VStack>
          </Box>

          {/* Input Controls */}
          <VStack spacing={4}>
            {currentMode.features.showTextInput && (
            <HStack width="full" spacing={4}>
              <Switch 
                colorScheme="teal" 
                isChecked={showTextInput} 
                onChange={() => setShowTextInput(!showTextInput)}
              />
              <Text>{showTextInput ? 'Hide' : 'Show'} Text Input</Text>
              </HStack>
            )}
            
            {showTextInput && (
              <HStack as="form" onSubmit={handleTextSubmit} width="100%">
                <Input 
                  value={input} 
                  onChange={(e) => setInput(e.target.value)} 
                  placeholder="Type your message..."
                  isDisabled={
                    isSimulationEnded || 
                    isRecording || 
                    (currentMode.id === 'exam' && timeRemaining <= 0)
                  }
                  bg={cardBg}
                  borderColor={primaryColor}
                  flex={1}
                />
                <IconButton 
                  icon={<FaPaperPlane />}
                  colorScheme="teal"
                  onClick={handleTextSubmit}
                  isDisabled={
                    isSimulationEnded || 
                    isRecording || 
                    !input || 
                    (currentMode.id === 'exam' && timeRemaining <= 0)
                  }
                  type="submit"
                  aria-label="Send message"
                />
              </HStack>
            )}

            {/* Microphone Button */}
            <Flex justify="center" width="full">
              <IconButton
                icon={isRecording ? <FaStop /> : <FaMicrophone />}
                colorScheme={isRecording ? "red" : "teal"}
                onClick={isRecording ? stopRecording : startRecording}
                isDisabled={
                    isSimulationEnded || 
                    (!isRecording && (isWaitingForResponse || isAudioPlaying)) ||
                  (currentMode.id === 'exam' && timeRemaining <= 0)
                }
                isRound={true}
                size="lg"
                width="80px"
                height="80px"
                fontSize="2xl"
                boxShadow="lg"
                _hover={{ transform: 'scale(1.05)', boxShadow: 'xl' }}
                transition="all 0.2s"
                aria-label={isRecording ? "Stop recording" : "Start recording"}
              />
            </Flex>
          </VStack>

          {/* Status Indicators */}
          {isEndingSimulation && (
            <Flex justify="center" mt={4}>
              <Spinner color={primaryColor} />
              <Text ml={2}>Ending simulation...</Text>
            </Flex>
          )}

          {/* Result Navigation */}
          {isSimulationEnded && (
            <Flex justify="center" mt={4}>
              <Button 
                colorScheme="teal"
                onClick={handleGoToResult}
                isDisabled={!feedback}
                boxShadow="md"
                _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                transition="all 0.2s"
              >
                Go to Result
              </Button>
            </Flex>
          )}

          {/* Hidden Audio Player */}
          <audio ref={audioRef} style={{ display: 'none' }} />
        </Container>
      </Box>

      {/* Artifact Panel - Only shown in non-exam modes when there's an artifact */}
      {currentMode.features.showHints && currentArtifact && (
        <Flex
          width="600px"
          bg={bg}
          borderLeft="1px solid"
          borderColor={borderColor}
          alignItems="center"
          justifyContent="center"
          p={2}
          ml={0}
        >
          <ArtifactPanel artifact={currentArtifact} />
        </Flex>
      )}
    </Flex>
  );
};

export default SimulationInterface;