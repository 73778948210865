import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Heading, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  VStack,
  HStack,
  useToast,
  Editable,
  EditableInput,
  EditablePreview,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon, CopyIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { scenarioService } from '../../services/scenarioService';

const ScenarioReader = () => {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    fetchScenarios();
  }, []);

  const fetchScenarios = async () => {
    try {
      const fetchedScenarios = await scenarioService.getScenarios();
      console.log('Fetched scenarios:', fetchedScenarios);
      setScenarios(fetchedScenarios);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching scenarios:', err);
      setError(`Failed to fetch scenarios: ${err.message}`);
      setLoading(false);
    }
  };

  const handleEdit = (scenarioName) => {
    // Use the scenario name as the identifier
    navigate(`/scenario-builder/${encodeURIComponent(scenarioName)}`);
  };

  const handleClone = async (scenarioName) => {
    try {
      const clonedScenario = await scenarioService.cloneScenario(scenarioName);
      setScenarios([...scenarios, clonedScenario.name]);
      toast({
        title: "Scenario cloned",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to clone scenario",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRename = async (oldName, newName) => {
    try {
      await scenarioService.renameScenario(oldName, newName);
      setScenarios(scenarios.map(s => s === oldName ? newName : s));
      toast({
        title: "Scenario renamed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to rename scenario",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const handleDelete = async (scenarioId) => {
    if (window.confirm('Are you sure you want to delete this scenario?')) {
      try {
        await scenarioService.deleteScenario(scenarioId);
        fetchScenarios();
        toast({
          title: "Scenario deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (err) {
        toast({
          title: "Failed to delete scenario",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleCreate = () => {
    navigate('/scenario-builder');
  };

  if (loading) return <Box>Loading scenarios...</Box>;
  if (error) return <Box color="red.500">Error: {error}</Box>;

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading as="h1" size="xl">Scenario Management</Heading>
        <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={handleCreate}>
          Create New Scenario
        </Button>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {scenarios.map((scenario) => (
              <Tr key={scenario}>
              <Td>
                <Editable
                  defaultValue={scenario}
                  onSubmit={(newName) => handleRename(scenario, newName)}
                >
                  {(props) => (
                    <HStack>
                      <EditablePreview />
                      <EditableInput />
                      <ButtonGroup size="sm" spacing={2}>
                        <IconButton icon={<CheckIcon />} {...props.submitButtonProps} />
                        <IconButton icon={<CloseIcon />} {...props.cancelButtonProps} />
                      </ButtonGroup>
                    </HStack>
                  )}
                </Editable>
              </Td>
                <Td>
                  <HStack spacing={2}>
                    <Button 
                      leftIcon={<EditIcon />} 
                      colorScheme="green" 
                      size="sm"
                      onClick={() => handleEdit(scenario)}
                    >
                      Edit
                    </Button>
                  <Button 
                    leftIcon={<CopyIcon />} 
                    colorScheme="blue" 
                    size="sm"
                    onClick={() => handleClone(scenario)}
                  >
                    Clone
                  </Button>
                    <Button 
                      leftIcon={<DeleteIcon />} 
                      colorScheme="red" 
                      size="sm"
                      onClick={() => handleDelete(scenario)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default ScenarioReader;