import React, { createContext, useContext, useState } from 'react';

export const SIMULATION_MODES = {
  PRACTICE: {
    id: 'practice',
    name: 'Practice Mode',
    features: {
      showHints: true,
      showFeedback: true,
      allowSkip: true,
      timeLimit: false,
      allowRetry: true,
      showTextInput: true,
      welcomeOverlayDuration: 0 // No forced duration
    }
  },
  EXAM: {
    id: 'exam',
    name: 'Exam Mode',
    features: {
      showHints: false,
      showFeedback: false,
      allowSkip: false,
      timeLimit: true,
      examDuration: 7 * 60, // 7 minutes in seconds
      allowRetry: false,
      showTextInput: true,
      welcomeOverlayDuration: 2 * 60, // 2 minutes in seconds
      autoStart: true // Automatically start after welcome overlay
    }
  },
  DEMO: {
    id: 'demo',
    name: 'Demo Mode',
    features: {
      showHints: true,
      showFeedback: true,
      allowSkip: true,
      timeLimit: false,
      allowRetry: true,
      showTextInput: true,
      welcomeOverlayDuration: 0,
      autoRespond: true
    }
  }
};

const SimulationModeContext = createContext(null);

export const SimulationModeProvider = ({ children }) => {
  const [currentMode, setCurrentMode] = useState(SIMULATION_MODES.PRACTICE);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [welcomeTimeRemaining, setWelcomeTimeRemaining] = useState(null);
  const [currentHint, setCurrentHint] = useState(null);

  const value = {
    currentMode,
    setCurrentMode,
    timeRemaining,
    setTimeRemaining,
    welcomeTimeRemaining,
    setWelcomeTimeRemaining,
    currentHint,
    setCurrentHint,
    SIMULATION_MODES
  };

  return (
    <SimulationModeContext.Provider value={value}>
      {children}
    </SimulationModeContext.Provider>
  );
};

export const useSimulationMode = () => {
  const context = useContext(SimulationModeContext);
  if (!context) {
    throw new Error('useSimulationMode must be used within SimulationModeProvider');
  }
  return context;
};