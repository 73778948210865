import React, { createContext, useState, useEffect, useContext } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import awsmobile from '../aws-exports';
import config from '../config'
const poolData = {
  UserPoolId: awsmobile.aws_user_pools_id,
  ClientId: awsmobile.aws_user_pools_web_client_id,
  Region: awsmobile.aws_cognito_region
};

export const userPool = new CognitoUserPool(poolData);

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    setIsLoading(true);
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(async (err, session) => {
          if (session && session.isValid()) {
            setIsAuthenticated(true);
            await fetchUserData(cognitoUser);
          } else {
            setIsAuthenticated(false);
            setUser(null);
          }
        });
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async (cognitoUser) => {
    // Check if we've fetched the data recently (e.g., within the last 5 minutes)
    if (lastFetchTime && (new Date().getTime() - lastFetchTime) < 5 * 60 * 1000) {
      console.log('Using cached user data');
      return;
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          console.error('Error fetching user attributes:', err);
          reject(err);
          return;
        }
        const userData = {};
        attributes.forEach(attribute => {
          userData[attribute.Name] = attribute.Value;
        });
        
        // Fetch additional user data from our backend
        fetch(`${config.API_BASE_URL}/api/user/${userData.sub}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            const combinedUserData = { ...userData, ...data };
            setUser(combinedUserData);
            setLastFetchTime(new Date().getTime());
            resolve(combinedUserData);
          })
          .catch(error => {
            console.error('Error fetching user data from backend:', error);
            setUser(userData);  // Fallback to only Cognito data
            resolve(userData);
          });
      });
    });
  };

  const signOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setIsAuthenticated(false);
      setUser(null);
      setLastFetchTime(null);
    }
  };

  const refreshUserData = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      fetchUserData(cognitoUser);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      setIsAuthenticated, 
      isLoading, 
      signOut, 
      checkAuthStatus, 
      user, 
      refreshUserData 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
