const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_6tHYuqINe",
  "aws_user_pools_web_client_id": "b23ggtevmplt9288d8rjcm4m1",
  "oauth": {
      "domain": "auth.oscecoach.com",
      "scope": ["email", "openid", "profile"],
      "redirectSignIn": "https://oscecoach.com/",
      "redirectSignOut": "https://oscecoach.com/",
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS"
};

export default awsmobile;