const config = {
    // Frontend: Local, Backend: Remote (Development Server)
    remote_backend_dev: {
        API_BASE_URL: 'http://MedSimALB-2072294945.us-east-1.elb.amazonaws.com',
        WEBSOCKET_URL: 'ws://MedSimALB-2072294945.us-east-1.elb.amazonaws.com',
    },
    // Frontend: Local, Backend: Local (Complete Local Development)
    local_backend: {
        API_BASE_URL: 'http://localhost:5000',
        WEBSOCKET_URL: 'http://localhost:5000',
    },
    // Production Environment (Frontend and Backend Live)
    production: {
        API_BASE_URL: 'https://api.oscecoach.com', // Use HTTPS in production
        WEBSOCKET_URL: 'wss://api.oscecoach.com',  // WebSocket should use secure wss
    },
};

const environment = process.env.REACT_APP_ENV || 'local_backend';

export default config[environment];
