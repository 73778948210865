import React from 'react';
import { Container, Box } from '@chakra-ui/react';

const Layout = ({ children }) => {
  return (
    <Box bg="gray.100" minHeight="100vh" py={4}>
      <Container maxW="container.lg">
        {children}
      </Container>
    </Box>
  );
};

export default Layout;