import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Text, SimpleGrid, VStack, Flex, AspectRatio } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';

const ResponsiveImage = ({ src, alt }) => (
  <AspectRatio ratio={16 / 9} width="100%" overflow="hidden">
  <Box
      as="div"
    backgroundImage={`url(${src})`}
    backgroundSize="cover"
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    pointerEvents="none"
    userSelect="none"
    aria-label={alt}
  />
  </AspectRatio>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const displayName = user?.display_name || 'User';

  const handleNavigation = (path) => {
      navigate(path);
  };

  const mainButtons = [
    {
      title: 'Start New Simulation',
      description: 'Choose a scenario to practice your communication skills',
      action: 'Choose Scenario',
      path: '/scenarios',
      image: '/images/start-simulation.jpg'
    },
    {
      title: 'My Progress',
      description: 'View your performance and improvement',
      action: 'View Progress',
      path: '/progress',
      image: '/images/my-progress.jpg'
    }
  ];

  return (
    <Box bg="gray.50" minHeight="100vh" py={8}>
      <Container maxW="container.xl">
        <Text fontSize="3xl" fontWeight="bold" mb={8} textAlign="center">
          Welcome to OsceCoach, {displayName}!
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={8}>
          {mainButtons.map((item, index) => (
            <VStack
              key={index}
              bg="white"
              boxShadow="md"
              borderRadius="lg"
              overflow="hidden"
              align="stretch"
              transition="all 0.3s"
              _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
              maxW="500px"
              mx="auto"
              w="100%"
            >
              <ResponsiveImage src={item.image} alt={item.title} />
              <VStack p={6} spacing={3} align="stretch">
                <Text fontSize="xl" fontWeight="semibold">{item.title}</Text>
              <Text>{item.description}</Text>
                <Button 
                  colorScheme="teal" 
                  size="lg" 
                  onClick={() => handleNavigation(item.path)}
                  mt={2}
                >
                {item.action}
              </Button>
            </VStack>
            </VStack>
          ))}
        </SimpleGrid>
        <Flex justifyContent="center">
          <Button 
            colorScheme="blue" 
            variant="outline" 
            onClick={() => handleNavigation('/scenario_edit')}
            size="md"
          >
            Build Scenarios
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default Dashboard;