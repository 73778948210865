import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Button, Text, Box, Heading, Container, Flex, VStack, HStack, 
  List, ListItem, Divider, Input, Select, Spinner, Tag,
  Radio, RadioGroup, Stack, useToast
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useSimulationMode } from '../contexts/SimulationModeContext';
import Layout from './Layout';
import config from '../config';

function ScenarioSelection() {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const { setCurrentMode, SIMULATION_MODES } = useSimulationMode();
  const [selectedMode, setSelectedMode] = useState('PRACTICE');
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    console.log('ScenarioSelection: Fetching scenarios');
    setLoading(true);
    fetch(`${config.API_BASE_URL}/api/scenarios`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('ScenarioSelection: Received scenarios:', data);
        setScenarios(data);
        setLoading(false);
      })
      .catch(e => {
        console.error('ScenarioSelection: Error fetching scenarios:', e);
        setError(e.message);
        setLoading(false);
      });
  }, []);

  const handleModeSelect = (value) => {
    setSelectedMode(value);
  };

  const handleScenarioSelect = (scenario) => {
    setSelectedScenario(scenario);
  };

  const handleStartScenario = () => {
    if (selectedScenario) {
      // Set the simulation mode before navigating
      const mode = SIMULATION_MODES[selectedMode];
      if (!mode) {
        toast({
          title: "Error",
          description: "Please select a valid simulation mode",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      
      setCurrentMode(mode);
      console.log('Starting scenario:', selectedScenario.id, 'with mode:', mode.name);
      navigate(`/simulation/${selectedScenario.id}`);
    }
  };

  const filteredScenarios = scenarios.filter(scenario => 
    scenario.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (categoryFilter === '' || scenario.category === categoryFilter)
  );

  const categories = [...new Set(scenarios.map(s => s.category))];

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Text color="red.500">Error: {error}</Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading as="h2" size="xl" mb={6}>Select a Scenario</Heading>
        <Flex>
          {/* Left side - Scenario List */}
          <Box width="50%" pr={4}>
            <VStack spacing={4} align="stretch">
              <HStack>
                <Input 
                  placeholder="Search scenarios..." 
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  leftElement={<SearchIcon color="gray.300" />}
                />
                <Select 
                  placeholder="All Categories"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                >
                  {categories.map(cat => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
                </Select>
              </HStack>
              <List spacing={2}>
                {filteredScenarios.map((scenario) => (
                  <ListItem 
                    key={scenario.id} 
                    p={2} 
                    bg={selectedScenario === scenario ? "blue.100" : "transparent"}
                    _hover={{ bg: "gray.100" }}
                    cursor="pointer"
                    onClick={() => handleScenarioSelect(scenario)}
                  >
                    <Text fontWeight="bold">{scenario.name}</Text>
                    <Text fontSize="sm" color="gray.600">{scenario.category}</Text>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>

          {/* Right side - Scenario Details and Mode Selection */}
          <Box width="50%" pl={4} borderLeft="1px" borderColor="gray.200">
            {selectedScenario ? (
              <VStack align="stretch" spacing={4}>
                <Heading as="h3" size="lg">{selectedScenario.name}</Heading>
                <Text>{selectedScenario.description}</Text>
                <Text><strong>Category:</strong> {selectedScenario.category}</Text>
                <Text><strong>Difficulty:</strong> {selectedScenario.difficulty}</Text>
                
                {/* Tags */}
                <Box>
                  <Text><strong>Tags:</strong></Text>
                  {selectedScenario.tags && selectedScenario.tags.map((tag, index) => (
                    <Tag key={index} mr={2} mb={2}>{tag}</Tag>
                  ))}
                </Box>

                {/* Mode Selection */}
                <Box bg="gray.50" p={4} borderRadius="md">
                  <Text fontWeight="bold" mb={3}>Select Simulation Mode:</Text>
                  <RadioGroup onChange={handleModeSelect} value={selectedMode}>
                    <Stack>
                      <Radio value="PRACTICE">
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="medium">Practice Mode</Text>
                          <Text fontSize="sm" color="gray.600">
                            Practice with hints and feedback
                          </Text>
                        </VStack>
                      </Radio>
                      <Radio value="EXAM">
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="medium">Exam Mode</Text>
                          <Text fontSize="sm" color="gray.600">
                            7-minute timed assessment
                          </Text>
                        </VStack>
                      </Radio>
                      <Radio value="DEMO">
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="medium">Demo Mode</Text>
                          <Text fontSize="sm" color="gray.600">
                            Explore the interface freely
                          </Text>
                        </VStack>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>

                <Button 
                  colorScheme="blue" 
                  onClick={handleStartScenario}
                  isDisabled={!selectedMode}
                >
                  Start Scenario
                </Button>
              </VStack>
            ) : (
              <Text>Select a scenario to view details</Text>
            )}
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default ScenarioSelection;