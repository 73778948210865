import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Heading,
  Box,
  Container,
  Button,
  Spinner,
  useToast,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
  ListIcon,
  Progress,
  VStack,
  HStack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import config from '../config';

const { API_BASE_URL } = config;

const SimulationResult = () => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const toast = useToast();
  const [retryCount, setRetryCount] = useState(0);

  const fetchFeedback = useCallback(async () => {
    if (!sessionId) {
      setError('Session ID not found. Unable to fetch feedback.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/api/coach_feedback/${sessionId}`);
      if (response.data.feedback && Object.keys(response.data.feedback).length > 0) {
        setFeedback(response.data.feedback);
      } else {
        setError('Received empty feedback from the server.');
      }
    } catch (err) {
      console.error('Error fetching feedback:', err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(`Server error: ${err.response.status} - ${err.response.data.detail || err.response.data}`);
      } else if (err.request) {
        // The request was made but no response was received
        setError('No response received from the server. Please check your connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setError(`Error setting up the request: ${err.message}`);
      }
      toast({
        title: "Error",
        description: `Failed to fetch feedback. ${err.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [sessionId, toast]);

  const renderGenericFeedback = () => (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Feedback Unavailable
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        {feedback.message}
      </AlertDescription>
    </Alert>
  );

  const renderDetailedFeedback = () => (
    <VStack spacing={6} align="stretch">
      <Box>
        <Heading as="h2" size="lg" mb={2}>Summary</Heading>
        <Text>{feedback.summary}</Text>
      </Box>
      
      <Box>
        <Heading as="h2" size="lg" mb={2}>Overall Rating</Heading>
        <HStack spacing={4}>
          <Progress value={feedback.overall_rating * 10} max={100} colorScheme="blue" width="100%" />
          <Text fontWeight="bold">{feedback.overall_rating}/10</Text>
        </HStack>
      </Box>
      
      <Box>
        <Heading as="h2" size="lg" mb={2}>Recommendations</Heading>
        <Text>{feedback.recommendations}</Text>
      </Box>
      
      <Divider />
      
      <Tabs>
        <TabList>
          {feedback.sections.map((section, index) => (
            <Tab key={index}>{section.section_name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {feedback.sections.map((section, sectionIndex) => (
            <TabPanel key={sectionIndex}>
              <VStack align="start" spacing={4}>
                <Heading as="h3" size="md">{section.section_name}</Heading>
                <List spacing={4} width="100%">
                  {section.items.map(renderCriterion)}
                </List>
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  );

  useEffect(() => {
    fetchFeedback();
  }, [fetchFeedback, retryCount]);

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    setRetryCount(prevCount => prevCount + 1);
  };
  
  const renderCriterion = (item) => (
    <ListItem key={item.criteria}>
      <HStack align="start" spacing={4}>
        <ListIcon
          as={item.met ? CheckIcon : CloseIcon}
          color={item.met ? "green.500" : "red.500"}
          mt={1}
        />
        <VStack align="start" spacing={1}>
          <Text fontWeight="bold">{item.criteria}</Text>
          <Text fontSize="sm">{item.justification}</Text>
        </VStack>
      </HStack>
    </ListItem>
  );

  return (
    <Container maxW="container.xl">
      <Box my={4}>
        <Heading as="h1" size="xl" mb={6}>Simulation Result</Heading>
        {error ? (
          <Box bg="red.100" p={4} borderRadius="md" mb={6}>
            <Text color="red.600">{error}</Text>
            <Button leftIcon={<RepeatIcon />} onClick={handleRetry} mt={2}>
              Retry
            </Button>
          </Box>
        ) : loading ? (
          <Box textAlign="center" mt={4}>
            <Spinner size="xl" />
          </Box>
        ) : feedback ? (
          feedback.is_generic ? renderGenericFeedback() : renderDetailedFeedback()
        ) : (
          <Text color="gray.500">
            No feedback available at this time. Please try again later.
          </Text>
        )}
        <Button
          colorScheme="blue"
          onClick={() => navigate('/dashboard')}
          mt={6}
        >
          Go back to the dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default SimulationResult;