import React, { useState } from 'react';
import { Button, Container, Input, Text, FormControl, FormLabel, InputGroup, InputRightElement, VStack, SimpleGrid, useToast } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { userPool } from '../contexts/AuthContext';
import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formState, setFormState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    displayName: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { email, firstName, lastName, displayName, password, confirmPassword } = formState;

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const validatePassword = (pass) => {
    return pass.length >= 8 && /\d/.test(pass) && /[a-zA-Z]/.test(pass);
  };

  const handlePasswordBlur = () => {
    if (!validatePassword(password)) {
      setPasswordError('Must be at least 8 characters, with at least one lowercase letter, one uppercase letter, and one number.');
      } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError('The passwords you entered do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validatePassword(password) || password !== confirmPassword) {
      return; // Don't proceed if passwords are invalid or don't match
    }

    try {
      const attributes = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
        new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
        new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
        new CognitoUserAttribute({ Name: 'nickname', Value: displayName }),
      ];

      userPool.signUp(email, password, attributes, null, (err, result) => {
        if (err) {
        console.error('Sign-up error:', err);
          if (err.code === 'UsernameExistsException') {
            // User exists but may not be confirmed
            handleExistingUser();
          } else {
        toast({
          title: "Sign-up failed",
          description: err.message || "An error occurred during sign-up",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
          }
        } else {
          console.log('Sign-up successful. Navigating to confirm-signup');
          navigateToConfirmSignUp(result.userSub);
        }
      });
    } catch (err) {
    console.error('Unexpected error during sign-up:', err);
    toast({
      title: "Unexpected error",
      description: "An unexpected error occurred. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

  const handleExistingUser = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        console.error('Error resending confirmation code:', err);
        toast({
          title: "Error",
          description: "Failed to resend confirmation code. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Confirmation code resent",
          description: "Please check your email for the confirmation code.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigateToConfirmSignUp();
      }
    });
  };

  const navigateToConfirmSignUp = (userSub = null) => {
    navigate('/confirm-signup', { 
      state: { 
        email,
        userSub,
        firstName,
        lastName,
        displayName
      } 
    });
  };
  return (
    <Container maxW="md">
      <VStack spacing={4} align="stretch" mt={8}>
        <FormControl>
          <FormLabel fontSize="sm">E-mail address</FormLabel>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </FormControl>

        <SimpleGrid columns={2} spacing={4}>
          <FormControl>
            <FormLabel fontSize="sm">First Name</FormLabel>
            <Input
              type="text"
              name="firstName"
              value={firstName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="sm">Last Name</FormLabel>
            <Input
              type="text"
              name="lastName"
              value={lastName}
              onChange={handleChange}
            />
          </FormControl>
        </SimpleGrid>

        <FormControl>
          <FormLabel fontSize="sm">Display Name</FormLabel>
          <Input
            type="text"
            name="displayName"
            value={displayName}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="sm">Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChange}
              onBlur={handlePasswordBlur}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          {passwordError && <Text color="red.500" fontSize="xs" mt={1}>{passwordError}</Text>}
          </FormControl>

        <FormControl>
          <FormLabel fontSize="sm">Confirm password</FormLabel>
          <InputGroup>
            <Input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
              onBlur={handleConfirmPasswordBlur}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {confirmPasswordError && <Text color="red.500" fontSize="xs" mt={1}>{confirmPasswordError}</Text>}
          </FormControl>

        <Button onClick={handleSignUp} colorScheme="blue" width="full">
            Sign Up
          </Button>

        <Text textAlign="center" fontSize="sm">
          Have an account? <RouterLink to="/login" style={{ color: 'blue' }}>Sign In</RouterLink>
      </Text>
      </VStack>
    </Container>
  );
};

export default SignUp;
