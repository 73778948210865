import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  Tooltip,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { InfoOutlineIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { scenarioService } from '../../services/scenarioService';
import yaml from 'js-yaml';

const ScenarioBuilder = () => {
  const [error, setError] = useState(null);
  const { scenarioName } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [scenario, setScenario] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (scenarioName) {
      fetchScenario();
    } else {
      setScenario({ name: '', description: '', agent_configs: { npcs: [{ npc_id: '', npc_role: '' }] } });
      setLoading(false);
    }
  }, [scenarioName]);
  
  const fetchScenario = async () => {
    try {
      const fetchedScenario = await scenarioService.getScenario(scenarioName);
      setScenario(fetchedScenario);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching scenario",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleInputChange = (key, value) => {
    setScenario(prevScenario => ({
      ...prevScenario,
      [key]: value
    }));
  };

  const handleNPCChange = (index, field, value) => {
    const updatedNPCs = scenario.agent_configs.npcs.map((npc, idx) =>
      idx === index ? { ...npc, [field]: value } : npc
    );
    setScenario(prevScenario => ({
      ...prevScenario,
      agent_configs: { ...prevScenario.agent_configs, npcs: updatedNPCs }
    }));
  };

  const addNPC = () => {
    setScenario(prevScenario => ({
      ...prevScenario,
      agent_configs: {
        ...prevScenario.agent_configs,
        npcs: [...prevScenario.agent_configs.npcs, { npc_id: '', npc_role: '' }]
      }
    }));
  };

  const removeNPC = (index) => {
    if (scenario.agent_configs.npcs.length > 1) {
      const updatedNPCs = scenario.agent_configs.npcs.filter((_, idx) => idx !== index);
      setScenario(prevScenario => ({
        ...prevScenario,
        agent_configs: { ...prevScenario.agent_configs, npcs: updatedNPCs }
      }));
    }
  };

  const isLeaf = (value) => typeof value !== 'object' || value === null;

  const renderInputField = (key, value, level = 0) => {
    // Extract the leaf key (last part of key after ".")
    const leafKey = key.split('.').pop();
    const indent = { marginLeft: `${level * 20}px` };

    // Special handling for agent_configs.npcs to render NPC form
    if (key === "agent_configs.npcs") {
      return (
        <Box key={key} style={indent} mt={2}>
          <Heading as="h4" size="sm" fontWeight="bold">Agent Configs - NPCs</Heading>
          <VStack align="stretch" spacing={4}>
            {scenario.agent_configs.npcs.map((npc, index) => (
              <Box key={index} mt={4} p={4} border="1px solid #ccc" borderRadius="md">
                <HStack justify="space-between" mb={2}>
                  <Heading as="h4" size="sm" fontWeight="bold">NPC {index + 1}</Heading>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Remove NPC"
                    onClick={() => removeNPC(index)}
                    size="sm"
                    colorScheme="red"
                    isDisabled={scenario.agent_configs.npcs.length === 1} // Disable if only one NPC remains
                  />
                </HStack>
                <FormControl>
                  <FormLabel fontWeight="bold">npc_id</FormLabel>
                  <Input
                    value={npc.npc_id}
                    onChange={(e) => handleNPCChange(index, 'npc_id', e.target.value)}
                  />
                </FormControl>
                <FormControl mt={2}>
                  <FormLabel fontWeight="bold">npc_role</FormLabel>
                  <Input
                    value={npc.npc_role}
                    onChange={(e) => handleNPCChange(index, 'npc_role', e.target.value)}
                  />
                </FormControl>
              </Box>
            ))}
            <IconButton
              icon={<AddIcon />}
              onClick={addNPC}
              size="sm"
              colorScheme="blue"
              aria-label="Add NPC"
              mt={2}
            />
          </VStack>
        </Box>
      );
    }

    if (isLeaf(value)) {
      return (
        <FormControl key={key} style={indent} mt={2}>
        <HStack spacing={2}>
            <FormLabel fontWeight="bold">{leafKey}</FormLabel>
            <Tooltip label={`Description of ${leafKey}`}>
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
          {key === "initial_message" ? (
            <Textarea
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              size="lg"
              minHeight="100px" // Fixed height for initial_message
            />
          ) : key === "npc_profiles.patrick_smith.npc_system_prompt" || key === "coach_feedback_prompt" ? (
            <Textarea
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              size="lg"
              minHeight="300px" // Larger text areas for prompts
            />
          ) : (
          <Input
            value={value}
            onChange={(e) => handleInputChange(key, e.target.value)}
          />
        )}
      </FormControl>
      );
    }

    return (
      <Box key={key} style={indent} mt={2}>
        <Heading as="h4" size="sm" fontWeight="bold">{leafKey}</Heading>
        <VStack align="stretch">
          {Object.entries(value).map(([nestedKey, nestedValue]) =>
            renderInputField(`${key}.${nestedKey}`, nestedValue, level + 1)
          )}
        </VStack>
      </Box>
    );
  };

  const handleSave = async () => {
    try {
      await scenarioService.updateScenario(scenarioName, scenario);
      toast({
        title: "Scenario updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/scenario-reader');
    } catch (error) {
      toast({
        title: "Error updating scenario",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <Box>Loading scenario...</Box>;
  }
  
  if (error) {
    return <Box color="red.500">Error: {error}</Box>;
  }
  
  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading as="h1" size="xl">Edit Scenario: {scenarioName}</Heading>
        {Object.entries(scenario).map(([key, value]) => renderInputField(key, value))}
        <Button colorScheme="blue" onClick={handleSave} mt={6}>Save Changes</Button>
      </VStack>
    </Box>
  );
};

export default ScenarioBuilder;