import React from 'react';
import { Container, VStack, Text, Box } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';

const Profile = () => {
  const { user } = useAuth();

  return (
    <Box>
      <Container maxW="container.md" pt={8}>
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold">Profile</Text>
          {user ? (
            <>
              <Text>Display Name: {user.display_name || 'N/A'}</Text>
              <Text>First Name: {user.first_name || 'N/A'}</Text>
              <Text>Last Name: {user.last_name || 'N/A'}</Text>
              <Text>Email: {user.email}</Text>
              <Text>Subscription Plan: {user.subscription_plan || 'Free'}</Text>
            </>
          ) : (
            <Text>Loading...</Text>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default Profile;